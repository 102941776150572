import React from "react";

import ButtonPricing from "./pricing-2-page-button";

import "../styles/pricing-2-page-card.scss" 

//import {
//  Button,
//} from "./ui"


// does the card leverage the power of flexbox spacing?

export default function Card({ position, primary, title, price, items, buttonText, onClick }) {
	/*
		position -> Removes border styling on right/left side if provided - str - left | right  
    primary -> flag that changes coloring - bool
    title -> plan title - str
    price -> plan price - str
    items -> plan features - array
  */
	console.log("___________pricing page:", items, buttonText, onClick)

	// Determine what classes to add to the card based on props
	let cardClass = "card ";

	if (primary) {
		cardClass += "primary";
	} else {
		cardClass += "secondary";
	}

	switch (position) {
		case "left":
			cardClass += " left";
			break;
		case "right":
			cardClass += " right";
			break;
		default:
			break;
	}

	return (
		<div className={cardClass}>
			<h3>{title}</h3>

			<h2>
				<span>$</span>
				{price}
			</h2>

			<ul>
				{items.map((item, idx) => {
					return <li key={idx}>{item}</li>;
				})}
			</ul>

			<ButtonPricing primary={primary} onClick={onClick}>{buttonText}</ButtonPricing>
{/*			<Button>Learn More</Button> */}
		</div>
	);
}
