//vn02 princing table
export const PricingData = {
	basic: {
		title: "Basic Trajectories",
		price: {
			monthly: "100",
			annually: "100",
		},
		benefits: [
			"per 100k trajectories one-off",
			"Commercial-grade trajectories that provide valuable insights into customer behavior and movement patterns, including basic demographic and economic attributes. ", 
			"Perfect for businesses just starting with location intelligence."
			],
	},
	professional: {
		title: "Advanced Trajectories",
		price: {
			monthly: "1,000",
			annually: "850",
		},
		benefits: [
			"per region & month", 
			"Includes intelligence-grade trajectories with full demographic and economic attributes. Not constrained to specific business segments, thus providing maximum analysis flexibility.", 
			"Ideal for those who require in-depth insights and analysis of customer behavior."
			],
	},
	master: {
		title: "Enterprise Access",
		price: {
			monthly: "10,000",
			annually: "8,000",
		},
		benefits: [
			"per business & month", 
			"Get the most out of Moveflow with our Enterprise-grade Access option.", 
			"Gain access to our comprehensive dashboard, analytics, and scenario simulator, along with dedicated resources and support to maximize your location intelligence capabilities."
			],
	},
};
