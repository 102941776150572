import React from "react"
//import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import {
  Container,
  Section,
  Text,
  Heading,
  Box,
  Space,
} from "../components/ui"

import Pricing from "../components/pricing-2";


//vn01: https://codepen.io/Cheesetoast/pen/KyQBKp - obsolete
//vn02: https://github.com/TinySquid/pricing-component <- gatsby made
//vn03: https://www.momonhg.com/blog/react-pricing-components <- not tested yet


// vn02:
const PricingPage = () => {
  return (
    <>
      <Header />
        <Container as="main" width="wide" >
          <Section padding={0} center >
            <Heading as="h1">Pricing</Heading>
            <Box width="threequarter" >
              <Text>
                At Moveflow, we recognize that each business has distinct location intelligence requirements. To cater to your specific needs, we provide versatile pricing options covering everything from basic trajectories to advanced analytics and tailored scenario builds.
              </Text>
              <Text>Explore our three pricing models below to find the right fit for your organization. If you require a more customized solution, don't hesitate to contact us — we're eager to collaborate and find the perfect solution to fuel your business growth.
              </Text>
            </Box>
            <Space size={4} />
            <Box width="full" >
              <Pricing />
            </Box>
          </Section>      
        </Container>
      <Footer />
    </>
  )
}
export default PricingPage




