
import React, { useState } from "react";

// Components
import Card from "./pricing-2-card";
import Slider from "./pricing-2-slider";

// Plan price text content
//import plans from "../content/pricing-2-plans";
import { PricingData } from "../content/pricing-2-plans";

// Style
import "../styles/pricing-2-page.scss" 
import {
  Heading,
} from "../components/ui"

//legacy:
const plans = PricingData
//console.log("_____________pricing page:",plans.basic)

export default function Pricing() {
	const [planPeriod, setPlanPeriod] = useState("monthly");

	function togglePeriod(sliderValue) {
		sliderValue ? setPlanPeriod("monthly") : setPlanPeriod("annually");
	}

	function handleLearnMore() {
		// Perform action for "Learn More" button
		window.location.href = 'mailto:sales@moveflow.tech';
		console.log("Learn More button clicked");
	}

	function handleComingSoon() {
		// Perform action for "Coming Soon" button
		console.log("Coming Soon button clicked");
	}

	return (
		<div className="pricing">
			<Heading style={{ fontSize: "1.8em" }}>
				Discover the unmatched precision, realism and coverage of Moveflow's Human Mobility Data Solutions.
			</Heading>

			<Slider onText="Monthly" offText="Annually" setValue={togglePeriod} />

			<div className="card-container">
				<Card
					position="left"
					title={plans.basic.title}
					price={
						planPeriod === "monthly"
							? plans.basic.price.monthly
							: plans.basic.price.annually
					}
					items={plans.basic.benefits}
					buttonText="Learn More"
					onClick={handleLearnMore}
				/>
				<Card
					primary
					title={plans.professional.title}
					price={
						planPeriod === "monthly"
							? plans.professional.price.monthly
							: plans.professional.price.annually
					}
					items={plans.professional.benefits}
					buttonText="Coming Soon"
				/>
				<Card
					position="right"
					title={plans.master.title}
					price={
						planPeriod === "monthly"
							? plans.master.price.monthly
							: plans.master.price.annually
					}
					items={plans.master.benefits}
					buttonText="Coming Soon"
				/>
			</div>
		</div>
	);
}