import React from "react";

import "../styles/pricing-2-page-button.scss" 

export default function ButtonPricing({ primary,onClick, children }) {
	// const handleClick = () => {
	// 	window.location.href = 'mailto:sales@moveflow.tech';
	// };

	return (
		<button className={primary ? "btn-pr primary" : "btn-pr secondary"} onClick={onClick}>
			{children}
		</button>
	);
}